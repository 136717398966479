import React from 'react'
import './about.css'
import { FaHome,FaHeart } from "react-icons/fa";

const About = () => {
  return (
    <div className='main-about'>
      
       <div className='about-icon-style'  data-aos="zoom-in" data-aos-duration="1000">
        <i className='about-icon'>
       <FaHome size = {130} style={{color: 'white'}}/> 
       </i>
       </div>

       <div className='about-wrap'>
        <h1 className='about-main-heading'>
        About Us
        </h1>
        <h2 className='about-heading'>
        A brief introduction of Ibdaa.
        </h2>
        <div id='point' className='radar'></div>
        <aside className='hatch' >
          <i></i>
          <dl>
            <dt className='heading-content'>Company</dt>
            <dd className='company-content'>Ibdaa</dd>
            <dt className='heading-content'>Email</dt>
            <dd className='company-content'>hello@ibdaa.com</dd>
            <dt className='heading-content'>Phone</dt>
            <dd className='company-content'>+1 (613) 324 5875</dd>
            {/* <dt className='heading-content'>Establish</dt> */}
            {/* <dd className='company-content'>Janury 2004</dd> */}
            <dt className='heading-content'>Location</dt>
            <dd className='company-content'>Canada & Pakistan</dd>
            {/* <dt className='heading-content'>category</dt> */}
            {/* <dd className='company-content'>Software Engineering</dd> */}
          </dl> 
        </aside>
        <p className='company-desc'>
          <strong className='paragraph-styling'>At Ibdaa, </strong>
          , we are more than a software development company; we are a passionate team of
            <em className='fade-color'> problem solvers</em> and <em className='fade-color'>innovators</em>
          . Our mission is to empower businesses through <em className='fade-color'>technology</em> and
          deliver value through our expertise, <em className='fade-color'>dedication</em>, and unwavering commitment to <em className='fade-color'>excellence</em>.
           We 
          <i> <FaHeart style={{color: '#ff9f18'}}/></i>
          what we do! Feel free to get in contact with us at any time without any obligation whatsoever.
          <strong className='paragraph-styling'>We look forward to hearing from you!</strong>
        </p>
       </div>
    </div>
  )
}

export default About
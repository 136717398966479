import React from 'react'
import './Feature.css'
import { FaCheck } from "react-icons/fa";

const Feature = () => {

  return (
    // <>
    <div className='feature-main'>
      <div className='icon-style' data-aos="fade-up" data-aos-duration="1000">
        <i className='icon'>
          <FaCheck size={130} style={{ color: 'white' }} />
        </i>
      </div>
      <div className='wrap'>
        <h1 className='feature-main-heading' data-aos="fade-right" data-aos-duration="700">We make building your product easy!</h1>
        <h2  className='feature-main-paragraph'  data-aos="fade-left" data-aos-duration="700">Here is a list of some of the sevices we provide.</h2>

        <div className='child-wrapper'>

        <div className='first-section-container' >
          
          <div className='first-left-data'  data-aos="fade-right" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/mobile-app-development (2).png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main' >
              <h1 className='mobile-heading' >
                Mobile App Development
              </h1>
              <p className='mobile-para' >
                Crafting engaging and user-friendly mobile
                experiences for iOS and Android.
              </p>
            </div>
          </div>

             
          <div className='second-right-data'   data-aos="fade-left" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/web-app-development (1).png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main' >
              <h1 className='mobile-heading'>
                Web App Development
              </h1>
              <p className='mobile-para'>
                Building web solutions that stand out in a crowded digital
                landscape
              </p>
            </div>
          </div>
        
        </div>
        
        <div className='first-section-container'>

          <div className='first-left-data'  data-aos="fade-right" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/data-visualization.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main'>
              <h1 className='mobile-heading' >
                BI & Data Analytics
              </h1>
              <p className='mobile-para' >
                Extracting insights from data to drive informed decision-making.
              </p>
            </div>
          </div>


          <div className='second-right-data'  data-aos="fade-left" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/artificial-intelligence.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main' >
              <h1 className='mobile-heading'>
                Artificial Intelligence
              </h1>
              <p className='mobile-para' >
                Harnessing AI to transform businesses and drive efficiency.
              </p>
            </div>
          </div>

        </div>

        <div className='first-section-container' >

        <div className='first-left-data'  data-aos="fade-right" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/custom.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main-align' >
              <h1 className='mobile-heading'>
              Custom Software Development
              </h1>
              <p className='mobile-para' >
              Tailoring software to meet your unique needs.
              </p>
            </div>
          </div>

          <div className='second-right-data'  data-aos="fade-left" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/devops.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main'>
              <h1 className='mobile-heading' >
              DevOps
              </h1>
              <p className='mobile-para' >
              Streamlining development and operations for a seamless workflow.
              </p>
            </div>
          </div>


        </div>

        <div className='first-section-container'  >

        <div className='first-left-data'  data-aos="fade-right" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/design.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main-align' >
              <h1 className='mobile-heading' >
              UI/UX
              </h1>
              <p className='mobile-para' >
              Designing intuitive and visually appealing user interfaces.
              </p>
            </div>
          </div>

          <div className='second-right-data'  data-aos="fade-left" data-aos-duration="1000">
            <i>
              <img
                src={require('../../assets/images/cpu.png')}
                alt='Description of your '
                style={{ width: '70px', height: '70px' }} // Adjust the style as needed
              />
            </i>
            <div className='text-main' >
              <h1 className='mobile-heading' >
              Embedded Technologies
              </h1>
              <p className='mobile-para' >
              Innovating with the latest embedded systems for IoT and beyond.
              </p>
            </div>
          </div>

        </div>

        </div>

      </div>
    </div>
    // </>
  )
}

export default Feature
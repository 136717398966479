import React, { useState, useEffect } from 'react'
import { TypeAnimation } from 'react-type-animation';
import './single.css'
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import { BiLogoUpwork } from 'react-icons/bi';
import { BsFire } from 'react-icons/bs';

// import images
import XTwitter from '../../assets/images/x-twitter.png'
import upWardImage from '../../assets/images/image2.png'
import downWardImage from '../../assets/images/image3.png'
import rightImage from '../../assets/images/image4.png'


const Single = () => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const [positionFixed, setPositionFixed] = useState(false)
  const [displayRope, setDisplayRope] = useState(false)


  useEffect(() => {

    const handleScrollDisplay = () => {
      if (window.scrollY >= 100) {
        setDisplayRope(true);
      } else {
        setDisplayRope(false);
      }
    };

    window.addEventListener('scroll', handleScrollDisplay);

    return () => {
      window.removeEventListener('scroll', handleScrollDisplay);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerWidth <= 950 ? 2300 : 2000;

      if (window.scrollY >= scrollThreshold) {
        setPositionFixed(true);
      } else {
        setPositionFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onAnimationComplete = () => {
    console.log("Animation completed");
    setAnimationFinished(true);
  };


  return (
    <div>

      {displayRope &&
        <>
          <div className={positionFixed ? 'positionFixed' : 'pipe'}>
            {/* <i className='fire-icon'></i> */}
            <i className='fire-icon' >
              <BsFire style={{ height: '200%', WebkitTransform: 'rotate(180deg)', marginLeft: '-12px' }} />
            </i>
          </div>

        </>
      }
      {/* <i className='fire-icon'>
          <BsFire  />
        </i> */}

      <div className='main' >
        <div className='left-image'>
          <img src={upWardImage} alt='image2' />
          <img src={downWardImage} alt='image3' style={{ marginTop: '-145px' }} />
        </div>
        <div>
          <a className='laptop-camera' href='#contact'></a>
          <div className='laptop-screen' >
            {animationFinished ? (
              <div className="scroll-down-message" style={{ fontSize: '2.5em', color: 'white' }}>Scroll Down</div>
            ) : (
              <TypeAnimation
                sequence={[
                  'HELLO!',
                  1000,
                  'Welcome to Ibdaa',
                  1000,
                  'Scroll Down',
                  1000

                ]}
                wrapper="span"
                speed={50}
                className='type-animation'
                repeat={onAnimationComplete}
                cursor={false}
              />
            )}
          </div>
          <div className='laptop'>
          </div>
          <nav className='laptop-social'>
            <a href="https://twitter.com/theibdaa" target="_blank" rel="noopener noreferrer" data-tooltip='Visit us on Twitter!' className='twitter'>
              <img src={XTwitter} alt="Twitter" className='social-icon' />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61553082536311" target="_blank" rel="noopener noreferrer" data-tooltip='Visit us on Facebook!'>
              <FaFacebook className='social-icon' />
            </a>
            <a href=" https://www.linkedin.com/company/theibdaa" target="_blank" rel="noopener noreferrer" data-tooltip='Visit us on Linkedin!'>
              <FaLinkedin className='social-icon' />
            </a>
            <a href="https://www.upwork.com/agencies/1711434178179698688/" target="_blank" rel="noopener noreferrer" data-tooltip='Visit us on Upwork!'>
              <BiLogoUpwork className='social-icon' />
            </a>

          </nav>
        </div>

        <div style={{ height: '100%' }}>
          <img src={rightImage} alt='image4' className='right-image' />
        </div>

      </div>

    </div>
  )
}

export default Single
import React from 'react'
import './process.css'
import { GoLightBulb } from "react-icons/go";
import { RiRocketLine } from 'react-icons/ri';
import { GiProgression } from "react-icons/gi";
import { PiTestTubeDuotone } from "react-icons/pi";
// import { LiaRocketSolid } from "react-icons/li";

// images import 
import delivery from '../../assets/images/delivery.png';
import development from '../../assets/images/development.png';
import ideation from '../../assets/images/ideation.png';
import testing from '../../assets/images/testing.png';

const Process = () => {

  return (
    <div className='mainn'>
       {/* <div className={false ? 'positionFixed' : 'pipe'}>
  <img
        src={require('../../assets/images/rope.png')} 
        alt='Description of your '
        style={{ width: '350px', height: '500px', marginLeft: '577px' }}
      />
</div> */}
      <div className='process-wrap'>

        <section className='process-section-1'>
          <div className='process-left-quote' style={{   width: '310px'}}
           data-aos="fade-right" data-aos-duration="700">
            <p className='first-section-paragraph' style={{ }}>"We start with a spark of creativity, transforming your ideas into actionable plans
              that drive innovation and growth."
            </p>

            {/* <blockquote className='blockquote'>"I need to sell products online."</blockquote> 
           <blockquote className='blockquote'>"I have a website but it's broken."</blockquote>
           <blockquote className='blockquote'>"I honestly don't know where to start."</blockquote> */}

          </div>
          <div className='circle'>
            <i className='process-circle' data-aos="flip-right" data-aos-duration="700">
              {/* <GoLightBulb size={90} className='bulb-icon' /> */}
              <img src={ideation} alt='ideation' className='image-resize' />
            </i>
          </div>
          <div className='process-right' data-aos="fade-left" data-aos-duration="700">
            <h1 className='process-heading'>The Ideation</h1>
            <p className='process-paragraph'>Where Innovation Takes Root.</p>
          </div>
        </section>



        <section className='process-section-1'>



          <div className='process-left-quote' style={{ marginTop: "-34px" , width: '310px'}} data-aos="fade-right" data-aos-duration="700">

          <h1 className='process-heading'>Development</h1>
            <p className='process-paragraph'>Let's start by discussing your idea and lay out everything that needs to be done.</p>

            {/* <p style={{ width: '311px', textAlign: 'left' }}>"Our developers are the architects who bring your vision to life. With precision and
              expertise, they construct the digital masterpiece you've imagined."
            </p> */}

          </div>

          <div className='circle'>
            <i className='process-circle' data-aos="flip-right" data-aos-duration="700">
              {/* <GiProgression size={90} className='bulb-icon' /> */}
              <img src={development} alt='ideation' className='image-resize'/>
            </i>
          </div>

          <div className='process-right' data-aos="fade-left" data-aos-duration="700">
            
            {/* <h1 className='process-heading'>Development</h1>
            <p className='process-paragraph'>Let's start by discussing your idea and lay out everything that needs to be done.</p> */}

            <p className='second-section-paragraph' style={{ }}>"Our developers are the architects who bring your vision to life. With precision and
              expertise, they construct the digital masterpiece you've imagined."
            </p> 
          </div>



        </section>




        <section className='process-section-1'>
          <div className='process-left-data' data-aos="fade-right" data-aos-duration="700">
            <p className='testing-describe' >"Before anything reaches your hands, it undergoes rigorous testing. We scrutinize
              every detail to deliver a flawless product."
            </p>
          </div>

          <div className='circle'>
            <i className='process-circle' data-aos="flip-right" data-aos-duration="700">
              {/* <PiTestTubeDuotone size={90} className='bulb-icon' /> */}
              <img src={testing} alt='ideation' className='image-resize'/>
            </i>
          </div>
          <div className='process-right' data-aos="fade-left" data-aos-duration="700">
            <h1 className='process-heading'>Testing</h1>
            <p className='process-paragraph'>Ensuring Perfection.</p>
          </div>
        </section>

        <section className='process-section-1'>
          <div className='process-left-quote' style={{marginTop: "-32px"}} data-aos="fade-right" data-aos-duration="700">
            {/* <p style={{ width: '311px', textAlign: 'left' }}>"On-time delivery is non-negotiable. We take immense pride in handing over the
              results of our hard work and dedication to your success
              "
            </p> */}
               <h1 className='process-heading'>Delivery</h1>
            <p className='process-paragraph'>Your Success, Our Pride.</p>
          </div>

          <div className='circle'>
            <i className='process-circle' data-aos="flip-right" data-aos-duration="700">
              {/* <RiRocketLine size={90} className='bulb-icon' /> */}
              <img src={delivery} alt='ideation' className='image-resize'/>
            </i>
          </div>
          <div className='process-right-last' data-aos="fade-left" data-aos-duration="700">
            {/* <h1 className='process-heading'>Delivery</h1>
            <p className='process-paragraph'>Your Success, Our Pride.</p> */}
            <p className='last-paragraph' style={{ }}>"On-time delivery is non-negotiable. We take immense pride in handing over the
              results of our hard work and dedication to your success "
            </p>
          </div>
        </section>

      </div>
    </div>
  )
}

export default Process
import './App.css';
import Single from './component/home/Single';
import Process from './component/process/Process';
import Feature from './component/feature/Feature';
import About from './component/about/About';
import Contacts from './component/contacts/Contacts';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// Add Font Awesome icons to the library
library.add(fas);
  


function App() {
  return (
    <div>
      <Single/>
      <Process/>
      <Feature/>
      <About/>
      <Contacts />
    </div>
  );
}

export default App;


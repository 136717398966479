import React from 'react'
import './contacts.css'
// images import 
import xTwitter from '../../assets/images/x-twitter.png'
import {   BsLinkedin } from 'react-icons/bs';
import  { BiLogoUpwork } from 'react-icons/bi'

const Contacts = () => {
    return (
        <div className='taz'>
            <section id="contact">
                <div className="header-icon">
                    <i className="tossing icon icon-128 icon-direction"> 
                    </i>
                </div>

               

                <div id="world">
                    <div id="sky">
                        <div id="moon">
                            </div>

                        <i className="cloud icon icon-64 icon-cloud-2"></i>
                        <i className="cloud icon icon-64 icon-cloud-3"></i>
                        <i className="cloud icon icon-64 icon-cloud-4"></i>
                        <i className="cloud icon icon-128 icon-cloud-2"><a href="https://twitter.com/share" title="Share us on Twitter!" className="twitter-share-button" data-url="https://dolox.com/" data-via="doloxinc" data-related="doloxinc" data-hashtags="doloxinc"></a></i>
                        <i className="cloud icon icon-128 icon-cloud-3"><i className="fb-like" data-href="https://www.facebook.com/doloxinc" data-send="false" data-layout="button_count" data-width="450" data-show-faces="false"></i></i>
                        <i className="cloud icon icon-128 icon-cloud-4"><i className="g-plusone" data-size="medium" data-href="https://plus.google.com/112198997029491940705"></i></i>

                        <div id="airspace">
                            <div id="blimp">
                                <div>
                                    <small>&copy; IBDAA. 2013</small>
                                    {/* <small><a href="http://nytm.org/" title="ISLAMABAD Tech Meetup" target="_blank">Made in PK<i className="icon icon-32 icon-heart heartbeat"></i></a></small> */}
                                    {/* <i id="blimp_tail_middle"></i> */}
                                </div>

                                <i id="blimp_tail_top"></i>
                                <i id="tail_smoke_1"></i>



                                <i id="blimp_tail_bottom"></i>
                                <i id="tail_smoke_2"></i>

                                <i id="carriage">
                                    <i className="window"></i>
                                    <i className="window"></i>
                                    <i className="door"></i>
                                </i>
                            </div>
                        </div>
                    </div>

                    <div id="city">
                        <div className="parallax"></div>
                        <div className="parallax2"></div>


                        <div id="signs">
                            <a id="twitter-sign" className="sign" href="https://twitter.com/theibdaa" title="Visit us on Twitter!" target="_blank" data-nudge="11">
                                <div><i className="icon icon-32 icon-twitter" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>  <img src={xTwitter} alt="Twitter" className='social-icon' style={{opacity: '1'}}/></i></div>
                                <div className="pole"></div>
                            </a>

                            <a id="facebook-sign" className="sign" href="https://www.facebook.com/profile.php?id=61553082536311" title="Visit us on Facebook!" target="_blank" data-nudge="11">
                                <div><i className="icon icon-32 icon-facebook"></i></div>
                                <div className="pole"></div>
                            </a>

                            <a id="blogger-sign" className="sign" href="https://www.linkedin.com/company/theibdaa" title="Visit our Blog!" target="_blank" data-nudge="11">
                                <div><i className="icon icon-32 icon-blogger"><BsLinkedin /></i></div>
                                <div className="pole"></div>
                            </a>

                            <a id="google-sign" className="sign" href="https://www.upwork.com/agencies/1711434178179698688/" title="Visit us on Google&plus;!" target="_blank" data-nudge="11">
                                <div><i className="icon icon-32 icon-google-plus"><BiLogoUpwork /></i></div>
                                <div className="pole"></div>
                            </a>
                        </div>

                        <div id="road"><div></div></div>
                        <div id="taxi"><div><i id="taxi_headlights"></i></div></div>
                    </div>
                </div>

                <div className="wrap">
                    <h1>You can contact us with no obligation.</h1>
                    <h2>Feel free to drop us a line or just to say hello!</h2>

                    <a id="get-in-touch" href="mailto:hello@theibdaa.com?subject=Saying%20Hello!" title="Contact us!" data-nudge="235">Get in Touch!</a>
                </div>
            </section>
        </div>
    )
}

export default Contacts